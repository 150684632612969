import { useState, useEffect } from "react";
import axios from "axios";
import URLShortenerCSS from "../css/urlshortener.module.css";
import "aos/dist/aos.css";

function URLShortener() {
  const [originalUrl, setOriginalUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [eldenDealsShortUrl, setEldenDealsShortUrl] = useState("");

  async function shortenUrl(originalUrl) {
    try {
      const response = await axios.post("/url/urlshorten", {
        originalUrl: originalUrl,
      });

      if (response.data.status !== 200) {
        throw new Error("Error");
      }

      return response.data.data;
    } catch (error) {
      console.error(
        "Error creating shortened URL:",
        error.response ? error.response.data : error.message
      );
      return originalUrl;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const shortURL = await shortenUrl(originalUrl);
    console.log(shortURL);

    setShortUrl(shortURL.shortUrl);
    setEldenDealsShortUrl(shortURL.eldenDealsShortUrl);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(shortUrl)
      .then(() => alert("URL copied to clipboard!"))
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const handleCopyEldenDeals = () => {
    navigator.clipboard
      .writeText(eldenDealsShortUrl)
      .then(() => alert("URL copied to clipboard!"))
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <div className={URLShortenerCSS["url-shortener"]}>
      <div className={URLShortenerCSS["url-shortener-container"]}>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={originalUrl}
            onChange={(e) => setOriginalUrl(e.target.value)}
            placeholder="Enter your long URL"
          />
          <button type="submit">Shorten URL</button>
        </form>
        {shortUrl && (
          <p onClick={handleCopy} className={URLShortenerCSS["short-url"]}>
            {shortUrl}
          </p>
        )}
        {shortUrl && (
          <p
            onClick={handleCopyEldenDeals}
            className={URLShortenerCSS["short-url"]}
          >
            {eldenDealsShortUrl}
          </p>
        )}
        <div className="button-group">
          <button onClick={handleCopy}>Copy First Url</button>
          <button onClick={handleCopyEldenDeals}>Copy Second Url</button>
        </div>
      </div>
    </div>
  );
}

export default URLShortener;
